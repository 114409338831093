<template>
  <section class="intro-x">

    <div class="my-4">
      <h1 class="font-bold text-xl">Facturacion Electrónica</h1>
    </div>

    <Toolbar class="box mt-4 p-p-2">
      <template #end>
        <Button
          v-if="$can('admin.administrativo.facturacion.permitir.gestionar.reception')"
          label="Agregar Registro"
          icon="pi pi-plus"
          class="p-button-success p-button-xs"
          v-tooltip.top="'Agregar Registro'"
          @click="openModalAgregarRegistro()"
        />
      </template>
    </Toolbar>
    <Dialog
      header="Agregar nuevo registro"
      v-model:visible="displayAgregarRegistro"
      :breakpoints="{'960px': '95vw'}"
      :style="{width: '70vw'}"
      :modal="true"
    >
      <form action="" @submit.prevent="onSubmit">

        <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-4">
          <div class="lg:col-span-4">
            <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
              <div class="md:col-span-2">
                <label for="registrationDate">Fecha de registro:</label>
                <input class="h-10 border mt-1 rounded px-2 w-full" type="date" id="registrationDate" v-model="modelo.registrationDate" disabled/>
              </div>

              <div class="md:col-span-2">
                <label for="documentTypes">Tipo de Documento:</label>
                <Dropdown
                  id="documentTypes"
                  v-model="modelo.documentType"
                  :options="documentTypes"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Seleccione una opción"
                  :filter="true"
                  scrollHeight="auto"
                  filterPlaceholder="Buscar por nombre"
                  :class="{ 'p-invalid': errors.documentType }"
                  class="h-10 border mt-1 rounded w-full"
                />
                <MessageError :text="errors.documentType" />
              </div>

              <div class="md:col-span-2">
                <label for="documentNumber">Prefijo / Num Documento:</label>
                <InputText
                  v-model="modelo.documentNumber"
                  id="documentNumber"
                  type="text"
                  placeholder="Prefijo + num doc"
                  class="h-10 border mt-1 rounded w-full"
                  :class="{ 'p-invalid': errors.documentNumber }"
                />
                <MessageError :text="errors.documentNumber" />
              </div>

              <div class="md:col-span-3">
                <label for="supplierTypes">Tipo de Proveedor:</label>
                <Dropdown
                  id="supplierTypes"
                  v-model="modelo.supplierType"
                  :options="supplierTypes"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Seleccione una opción"
                  :filter="true"
                  scrollHeight="auto"
                  filterPlaceholder="Buscar por nombre"
                  :class="{ 'p-invalid': errors.supplierType }"
                  class="h-10 border mt-1 rounded w-full"
                  @change="changeSupplierType"
                />
                <MessageError :text="errors.supplierType" />
              </div>

              <div class="md:col-span-3">
                <label for="banco">Proveedor:</label>
                <AutocompleteProveedores
                  v-model="modelo.supplier"
                  :classes="{
                    'w-full': true,
                    'mt-1': true,
                    'rounded': true,
                    'p-invalid': errors.supplier
                  }"
                  :disabled="isDisabledSupplier"
                  :supplierType="modelo.supplierType"
                  @setSupplier="onSetSupplier"
                />
                <input type="hidden" v-model="modelo.providerName">
                <MessageError :text="errors.supplier" />
              </div>

              <div class="md:col-span-6" v-if="modelo.supplierType === 1">
                <label for="banco">Usuario:</label>
                <AutocompleteEmployees
                  v-model="modelo.employee"
                  :classes="{
                    'w-full': true,
                    'mt-1': true,
                    'rounded': true,
                    'p-invalid': errors.employee
                  }"
                />
                <MessageError :text="errors.employee" />
              </div>

              <div class="md:col-span-6">
                <label for="myDropzone" >Adjuntar documento:</label>
                <FilePond ref="pondFile" @captureFile="onCaptureFile" @removeFile="onRemoveFile" />
                <MessageError :text="errors.attach" />
              </div>
            </div>
          </div>

        </div>

      </form>
      <template #footer>
        <Button label="Cerrar" icon="pi pi-times" @click="closeModalAgregarRegistro" class="p-button-xs p-button-danger"/>
        <Button label="Guardar" icon="pi pi-check" @click="onSubmit" class="p-button-xs p-button-success" autofocus />
      </template>
    </Dialog>
    <TableRegister :permissionUser="getStagePermission()"></TableRegister>

  </section>
</template>

<script>
import { defineAsyncComponent, ref } from 'vue'
import { useField, useForm } from 'vee-validate'
import dayjs from 'dayjs'
import { object, string } from 'yup'
import { error, messageConfirmSave, success } from '../../../../../../../libs/mensajes'
import saveInvoicing from '../../../../services/saveInvoicing'
import updateStageInvoicingService from '../../../../services/updateStageInvoicingService'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'FacturacionHome',
  components: {
    FilePond: defineAsyncComponent(() => import('../components/uploadFilePond')),
    AutocompleteProveedores: defineAsyncComponent(() => import('../components/proveedores')),
    AutocompleteEmployees: defineAsyncComponent(() => import('../components/employees')),
    TableRegister: defineAsyncComponent(() => import('../components/tableRegister'))
  },
  setup (props, context) {
    const pondFile = ref(null)
    const isDisabledSupplier = ref(true)
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const commonPeople = store.getters['auth/getPeople']

    /* get user permission auth */
    const getStagePermission = () => {
      const permissions = store.getters['auth/getPermissions']
      if (store.getters['auth/isAdmin']) {
        return ['Reception', 'Area', 'Register', 'Account', 'Manager', 'Request']
      }
      const stagePermission = []
      if (permissions.includes('admin.administrativo.facturacion.permitir.gestionar.reception')) {
        stagePermission.push('Reception')
      }
      if (permissions.includes('admin.administrativo.facturacion.permitir.gestionar.request')) {
        stagePermission.push('Request')
      }
      if (permissions.includes('admin.administrativo.facturacion.permitir.gestionar.area')) {
        stagePermission.push('Area')
      }
      if (permissions.includes('admin.administrativo.facturacion.permitir.gestionar.register')) {
        stagePermission.push('Register')
      }
      if (permissions.includes('admin.administrativo.facturacion.permitir.gestionar.account')) {
        stagePermission.push('Account')
      }
      if (permissions.includes('admin.administrativo.facturacion.permitir.gestionar.manager')) {
        stagePermission.push('Manager')
      }
      return stagePermission
    }

    const documentTypes = ref([
      { id: 2, name: 'Factura' },
      { id: 1, name: 'Nota Crédito' },
      { id: 3, name: 'Nota Débito' }
    ])
    const supplierTypes = ref([
      { id: 1, name: 'Administrativos' },
      { id: 2, name: 'Medicamentos' }
    ])
    const displayAgregarRegistro = ref(false)
    const embedSrc = ref('')

    /* Abrir el modal */
    const openModalAgregarRegistro = () => {
      displayAgregarRegistro.value = true
      isDisabledSupplier.value = true
      handleReset()
    }

    /* Cerrar el modal */
    const closeModalAgregarRegistro = () => {
      displayAgregarRegistro.value = false
      handleReset()
    }

    /* Habilita el autocomplete de proveedores */
    const changeSupplierType = (e) => {
      isDisabledSupplier.value = [null, '', undefined, 0].includes(e.value)
      modelo.supplier = null
      modelo.employee = null
      modelo.providerName = null
    }

    /* Obtener nombre proveedor */
    const onSetSupplier = (value) => {
      modelo.supplier = value.cardcode
      modelo.providerName = value.cardname
    }

    /* Capturar el adjunto de filepond */
    const onCaptureFile = (files) => {
      modelo.attach = files
    }

    /* Limpiando el attach cuando se remueve o reemplaza el archivo */
    const onRemoveFile = (files) => {
      modelo.attach = null
    }
    /* Esquema de validacion con sus reglas */
    const validationSchema = object({
      registrationDate: string().nullable().required().label('Fecha de registro'),
      documentType: string().nullable().required().label('Tipo de Documento'),
      documentNumber: string().nullable().required().label('Prefijo / Num Documento'),
      supplierType: string().nullable().required().label('Tipo de Proveedor'),
      supplier: string().nullable().when(['supplierType', 'documentType'], (supplierType, documentType) => {
        if (supplierType === '2' || documentType !== '2') {
          return string().nullable().required('Debe seleccionar un proveedor del listado').label('Proveedor')
        }
      }),
      providerName: string().nullable().required().label('Nombre Proveedor'),
      attach: string().nullable().required().label('Adjuntar Documento'),
      employee: string().nullable().when('supplierType', (supplierType) => {
        if (supplierType === '1') {
          return string().nullable().required().label('Usuario')
        }
      })
    })

    /* Variables y propiedades del formulario para validar */
    const { errors, handleSubmit, values: modelo, handleReset } = useForm({
      validationSchema
    })

    /* Inicializando campo en el formulario */
    useField('registrationDate', null, { initialValue: dayjs().format('YYYY-MM-DD') })
    useField('documentType', null, { initialValue: null })
    useField('documentNumber', null, { initialValue: null })
    useField('supplierType', null, { initialValue: null })
    useField('supplier', null, { initialValue: null })
    useField('providerName', null, { initialValue: null })
    useField('employee', null, { initialValue: null })
    useField('attach', null, { initialValue: null })

    /* Submit */
    const onSubmit = handleSubmit((values) => {
      const formData = new FormData()

      formData.append('registrationDate', `${values.registrationDate} ${dayjs().format('HH:mm:ss')}`)
      formData.append('typeProvider', values.supplierType)
      formData.append('providerCode', values.supplier)
      formData.append('providerName', values.providerName)
      formData.append('documentType', values.documentType)
      formData.append('documentNumber', values.documentNumber)
      formData.append('adjunct', values.attach)
      formData.append('receiver', values.employee)

      messageConfirmSave('', 'Registrar Documento', 'warning', '¿Esta seguro de guardar el registro del documento?').then(({ isConfirmed }) => {
        if (isConfirmed) {
          saveInvoicing(formData).then((res) => {
            // loader.hide()
            success('Documento registrado exitosamente')
            displayAgregarRegistro.value = false
            modelo.attach = null
            handleReset()
            updateStageInvoicing(res.data.id, res.data.typeProvider)
          }).catch((err) => {
            // loader.hide()
            console.error(err.message)
            if (err.response.status) {
              error(err.response.data.message)
            }
          })
        }
      })
    })

    const updateStageInvoicing = (id, typeProvider) => {
      const data = {
        stage: 'Reception',
        invoiceId: id,
        userId: commonPeople.id,
        status: 'Aceptado',
        note: '',
        typeProvider: typeProvider
      }
      updateStageInvoicingService(data).then((result) => {
        const queryParams = route.query
        router.push({ query: { ...queryParams, refresh: Math.random() * (1000 - 1) + 1 } })
      })
    }
    return {
      commonPeople,
      displayAgregarRegistro,
      openModalAgregarRegistro,
      closeModalAgregarRegistro,
      onSubmit,
      modelo,
      documentTypes,
      errors,
      supplierTypes,
      embedSrc,
      onCaptureFile,
      onRemoveFile,
      changeSupplierType,
      onSetSupplier,
      isDisabledSupplier,
      pondFile,
      getStagePermission
    }
  }
}
</script>

<style scoped>

</style>
